const locations = {
  "National Public Health Reference laboratory": [5.538562, -0.228688],
  "Noguchi Memorial Institute for Medical Research ": [5.6356669, -0.1820556],
  "Kumasi Center for collaborative  Research": [6.6697226, -1.5764952],
  "Veterinary Service Department Research-labone": [5.5571036, -0.167912],
  "Veterinary Service Department-takoradi": [4.9048193, -1.7540561],
  "Public Health Reference Laboratory-tamale": [9.3963814, -0.8271043],
  "Public health Reference laboratory-sekondi Takoradi": [
    4.9424054, -1.7514506,
  ],
  "University Health and Allied science laboratory-ho": [6.5991368, 0.4992105],
  "MDS-LANCET-EAST LAGON": [9.3963814, -0.8271043],
  "Nyaho Diagnostic Laboratory-Accra": [5.6142401, -0.1850195],
  "Akai house laboratory-Accra": [5.5771875, -0.1715625],
  "Council For Scientific And Industrial Research - Accra": [
    5.596625, -0.1850625,
  ],
  "LEDing Medical Laboratory": [5.6448125, -0.1560625],
  "The Trust Hospital Company Ltd": [5.56268875, -0.1823125],
  "Synlab Ghana": [5.6015625, -0.1929375],
  "Omni Diagnostics": [5.5973125, -0.1513125],
  GENELAB: [5.6405625, -0.1453125],
  "FRONTIERS HEALTHCARE SERVICES": [5.6036875, -0.18629375],
  "AIRPORT CLINIC": [5.6039375, -0.1720625],
  "Sonotech Medical & Diagnostic Center": [5.6758125, -0.0169375],
  "Alma Medical Laboratory": [5.5555625, -0.1821875],
  "Spintex Community Hospital": [5.6376875, -0.1065625],
  "HAG Hospital": [5.667438, -0.116563],
  "Quadushah Medical Diagnostic Centre Ltd": [5.6636875, -0.0265625],
  "MedTech Laboratory": [5.5719375, -0.1661875],
  "Impact Medical And Diagnostic Centre": [5.5705625, -0.2015625],
  "Lapaz Community Hospital": [5.6085625, -0.2534375],
  "Yemaachi Biotech": [5.6071875, -0.2156875],
  "Danpong Medical Centre": [5.6278125, -0.08866875],
  "Claron Medical Centre": [5.6123125, -0.1823125],
};

export default locations;
